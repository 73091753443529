import React, {useMemo} from 'react';
import styled from "styled-components";
import HeaderDescription from "@/components/common/Header&Description";
import Container from "@/components/common/Container";
import {getCssSizeValue, getPaddingsClassFromData} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import GridContainer from "@/components/common/GridContainer";
import {useBranch} from "@/hooks/useBranch";
import Cards, {formatToCardItem} from "@/components/common/Cards";
import LinkButton from "@/components/common/Buttons/LinkButton";
import {BUTTON_VARIANTS} from "@/constants";

const Wrapper = styled(Container)`
	--row-gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.colors.white};
    row-gap: var(--row-gap);

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        --row-gap: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
		.cards-wrapper{
            display: contents;
		}
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --row-gap: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
    }
	
	.cards-content, .top{
        row-gap: var(--row-gap);
	}
`

const Top = styled(GridContainer)`
	width: 100%;
`

const ButtonWrapper = styled.div`
	display: flex;
    align-items: start;
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        justify-content: end;
	}
`

const MAX_LENGTH = 8

const WhatWeDoPreview = ({data}) => {
	const id = data?.id || ''
	const headerDescription = data?.headerDescription || {header: '', description: ''}
	const { selectedOption } = useBranch()
	const paddings = getPaddingsClassFromData(data);

	//filter whatwedos by current state context
	const whatWeDos = useMemo(() => {
		const wwdsCat = data?.categories?.map((item) => formatToCardItem(item)) || []
		const wwds = data?.actions?.map((item) => formatToCardItem(item)) || []
		if(wwds.length <= 0) return []
		const filtered = wwds.filter((wwd) => wwd?.state?.handle?.toLowerCase() === selectedOption);
		const finalData = wwdsCat.concat(filtered);
		return finalData.slice(0,MAX_LENGTH)
	}, [data, selectedOption])

	return (
		<Wrapper key={id} className={paddings}>
			<Top className="no-margin top">
				<HeaderDescription data={headerDescription} className="col-mobile-8 col-tablet-5 col-desktop-8"/>
				<ButtonWrapper className="col-mobile-8 col-tablet-3 col-desktop-8" >
					<LinkButton data={data?.button} variant={BUTTON_VARIANTS.TERTIARY}/>
				</ButtonWrapper>
			</Top>
			<Cards data={whatWeDos}/>
		</Wrapper>
	);
};

export default WhatWeDoPreview;
